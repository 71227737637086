import React from 'react';
import { useRecoilState } from 'recoil'
import { collapsedState } from '../../utils/recoil-atoms'
import { Link } from 'gatsby'
import logo from "../../assets/images/logo.png"
import starIcon from '../../assets/images/star-icon.png'

const Navbar = () => {
    const [collapsed, setCollapsed] = useRecoilState(collapsedState);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    React.useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    })

    const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <React.Fragment>
            <div id="navbar" className="navbar-area">
                <div className="tarn-nav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <Link
                                to="/"
                                onClick={() => setCollapsed(true)}
                                className="navbar-brand">
                                <svg className="logo" version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style={{ "enable-background": "new 0 0 512 512" }}>
                                    <g>
                                        <linearGradient xmlns="http://www.w3.org/2000/svg" id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="23" y1="258.0002" x2="489" y2="258.0002" gradientTransform="matrix(1 0 0 -1 0 514.0002)"><stop stop-opacity="1" stop-color="#ff5d22" offset="0.03861003861003861"></stop><stop stop-opacity="1" stop-color="#ff5d22" offset="0.18532818532818532"></stop><stop stop-opacity="1" stop-color="#ff5d22" offset="0.18532818532818532"></stop><stop stop-opacity="1" stop-color="#ff5d22" offset="0.3204633204633205"></stop><stop stop-opacity="1" stop-color="#ff5d22" offset="0.3861003861003861"></stop><stop stop-opacity="1" stop-color="#ff5d22" offset="0.3861003861003861"></stop><stop stop-opacity="1" stop-color="#ff5d22" offset="0.3861003861003861"></stop><stop stop-opacity="1" stop-color="#ff5d22" offset="0.3861003861003861"></stop><stop stop-opacity="1" stop-color="#ff5d22" offset="0.3861003861003861"></stop><stop stop-opacity="1" stop-color="#42445a" offset="1"></stop><stop stop-opacity="1" stop-color="#ff5d22" offset="1"></stop></linearGradient>
                                        <path xmlns="http://www.w3.org/2000/svg" style={{ "fill": "url(#SVGID_1_)" }} d="M214.793,501.006L64.284,414.205C38.819,399.52,23,372.133,23,342.733V169.266  c0-29.4,15.819-56.787,41.285-71.472l150.508-86.801c25.417-14.657,56.998-14.658,82.414,0l150.509,86.801  C473.181,112.48,489,139.867,489,169.266v173.467c0,29.4-15.819,56.787-41.285,71.472l-150.508,86.801  C271.794,515.662,240.214,515.667,214.793,501.006z 
										M234.774,45.651L84.266,132.452C71.148,140.016,63,154.123,63,169.266v173.467  c0,15.144,8.148,29.25,21.265,36.815l150.509,86.801c13.093,7.552,29.358,7.552,42.451,0l150.509-86.801  C440.852,371.984,449,357.877,449,342.733V169.266c0-15.144-8.148-29.25-21.265-36.815l-150.509-86.8  C264.138,38.102,247.868,38.099,234.774,45.651z 
										M162.713,334.132l74.281-62.863c9.45-7.997,9.423-22.565,0-30.54l-74.281-62.863  c-8.431-7.136-21.052-6.085-28.187,2.349c-7.135,8.434-6.083,21.055,2.349,28.191L193.113,256l-56.238,47.593  c-8.432,7.136-9.483,19.757-2.349,28.191C141.678,340.236,154.302,341.251,162.713,334.132z" fill=""></path>
                                    </g>
									<g>
                                        <linearGradient xmlns="http://www.w3.org/2000/svg" id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="23" y1="258.0002" x2="489" y2="258.0002" gradientTransform="matrix(1 0 0 -1 0 514.0002)"><stop stop-opacity="1" stop-color="#ff5d22" offset="0.03861003861003861"></stop><stop stop-opacity="1" stop-color="#ff5d22" offset="0.18532818532818532"></stop><stop stop-opacity="1" stop-color="#ff5d22" offset="0.18532818532818532"></stop><stop stop-opacity="1" stop-color="#ff5d22" offset="0.3204633204633205"></stop><stop stop-opacity="1" stop-color="#ff5d22" offset="0.3861003861003861"></stop><stop stop-opacity="1" stop-color="#ff5d22" offset="0.3861003861003861"></stop><stop stop-opacity="1" stop-color="#ff5d22" offset="0.3861003861003861"></stop><stop stop-opacity="1" stop-color="#ff5d22" offset="0.3861003861003861"></stop><stop stop-opacity="1" stop-color="#ff5d22" offset="0.3861003861003861"></stop><stop stop-opacity="1" stop-color="#42445a" offset="1"></stop><stop stop-opacity="1" stop-color="#ff5d22" offset="1"></stop></linearGradient>
                                        <path xmlns="http://www.w3.org/2000/svg" style={{ "fill": "url(#SVGID_1_)" }} d="M382.206,318.862  c0-11.048-8.954-20.003-20-20.003h-89.995c-11.046,0-20,8.955-20,20.003s8.954,20.003,20,20.003h89.995  C373.251,338.865,382.206,329.91,382.206,318.862z" fill="">
                                        <animate
                                            attributeName="fill"
                                            values="#554654;transparent"
                                            begin="0s"
                                            dur="2s"
                                            calcMode="discrete"
                                            repeatCount="indefinite"
                                        />
                                        </path>
                                    </g>
                                </svg>
                                <span className="navbar-title">IT Sols And Services</span>
                            </Link>

                            {<button
                                onClick={toggleNavbar}
                                className={classTwo}
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>}

                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link
                                            to="/services"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link"
                                        >
                                            Services
                                                </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="/portfolio"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link"
                                        >
                                            Portfolio
                                                </Link>

                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="/faqs"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link"
                                        >
                                            FAQs
                                        </Link>

                                    </li>
                                </ul>

                                <div class="btn-container">
                                    <button onClick={() =>  window.location.href='/contact'}>
                                        <span class="text">Get Started</span>
                                        <div class="icon-container">
                                            <div class="icon icon--left">
                                                <svg>
                                                    <g id="arrow-right" viewBox="0 0 20 10">
                                                        <path d="M14.84 0l-1.08 1.06 3.3 3.2H0v1.49h17.05l-3.3 3.2L14.84 10 20 5l-5.16-5z"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div class="icon icon--right">
                                                <svg>
                                                    <g id="arrow-right" viewBox="0 0 20 10">
                                                        <path d="M14.84 0l-1.08 1.06 3.3 3.2H0v1.49h17.05l-3.3 3.2L14.84 10 20 5l-5.16-5z"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </button>
                                </div>

                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Navbar;
